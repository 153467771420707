import React from 'react';

import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import './kosmate.css';

const SectionTitle = ({id, children}) => (
    <section id={id}>
        <header>
            <h2>{children}</h2>
        </header>
    </section>
);

function Item({name, base, num_thumb, pricing, view, children}) {
    const thumbs = [];
    for (let i = 1; i <= num_thumb; i++) {
        let name = base + '-' + i;
        let thumb = 'img/' + name + '-th.jpg';
        let full = 'img/' + name + '.jpg';
        let key = 'im-' + name;
        thumbs.push(<Col key={key} xs="auto"><Image src={thumb} alt="miniatura" thumbnail onClick={() => view(full)} /></Col>);
    }
    return(
    <section>
        <header>
            <h3>{name}</h3>
        </header>
        <Container fluid>
            <Row>
                <Col md="auto">
                    <Image className="prod" src={'img/'+base+'-200px.jpg'} alt={name} />
                </Col>
                <Col>
                    <Container fluid className="h-100 d-flex flex-column">
                        <Row className="flex-grow-1">
                            <Col className="p-3">
                                {children}
                            </Col>
                        </Row>
                        <Row>
                            {thumbs}
                            <Col>
                                <table className="pricing"><tbody>
                                <tr><th className="p-2">Cena</th><th className="p-2">Opis</th></tr>
                                {pricing.map((item, i_index) => (
                                    <tr key={base + '-' + i_index}><td className="p-2">{item[0]}</td><td className="p-2"><ul>
                                    {item.slice(1).map((feature, f_index) => (
                                        <li key={base + '-' + i_index + '-' + f_index}>{feature}</li>
                                    ))}
                                    </ul></td></tr>
                                ))}
                                </tbody></table>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    </section>
    );
}

const Other = ({name, id, children}) => (
    <section id={id}>
        <header>
            <h3>{name}</h3>
        </header>
        <Container fluid>
            {children}
        </Container>
    </section>
);

export default function Kosmate() {
    
    const [modalShow, setModalShow] = React.useState(false);
    const [modalSrc, setModalSrc] = React.useState('');
    
    function openModal(source) {
        setModalSrc(source);
        setModalShow(true);
    }
    
    return (
        <Container>
            <Navbar expand="lg" className="navbar-dark fixed-top bg-dark">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                    <Nav>    
                        <Nav.Link href="#offer">Oferta stała</Nav.Link>
                        <Nav.Link href="#finish">Warianty wykończenia</Nav.Link>
                        <Nav.Link href="#contact">Kontakt</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        
            <section>
                <header>
                    <Stack direction="horizontal" gap={3}>
                        <Image src="img/kosmate-bw.png" alt="logo" />
                        <div>
                            <h1>Kosmate Meble</h1>
                            Dla tych osób, którym zwykłe trzymanie się za ręce nie wystarcza...
                        </div>
                    </Stack>
                </header>
            </section>
        
            <SectionTitle id={'offer'}>Oferta Stała</SectionTitle>

            <Item name={'Krzyż św. Andrzeja'} base={'xcross'} num_thumb={2} pricing={[['1000 zł', 'mebel z zaczepami', 'zestaw kotw montażowych', 'pokrycie bejcą']]} view={openModal}>
                Lekka minimalistyczna konstrukcja pozwala skupić się na celu, zamiast na środkach koniecznych do jego osiągnięcia. Niewielkie wymiary oraz masa tego rozwiązania sprzętowego czynią transport prostym a instalację przyjmeną. Dla zapewnienia stabilnej i bezawaryjnej pracy konieczne jest przykręcenie iksa do ściany. Odpowiednie kotwy są częścią zestawu. Każdy, kto kiedykolwiek wiercił w ścianie powinien dać sobie z tym radę. 
            </Item>
            <Item name={'Podświetlony krzyż'} base={'xcrossled'} num_thumb={1} pricing={[['1600 zł', 'mebel z zaczepami', 'zestaw kotw montażowych', 'pokrycie bejcą', 'podświetlenie']]} view={openModal}>
                Mebel wykonany i wykończony podobnie jak powyższy. Dodatkowo jednak jego obrys został podkreślony kolorowym dynamicznym podświetleniem.
            </Item>
            <Item name={'Dyby'} base={'pillory'} num_thumb={4} pricing={[['1500 zł', 'mebel z zasuwką', 'obicie otworu na szyję', 'pokrycie bejcą']]} view={openModal}>
                Właściwie, to nie są dyby, tylko gąsior <a href="https://pl.wikipedia.org/wiki/G%C4%85sior_(narz%C4%99dzie_kary)" className="cite" target="_blank" rel="noreferrer">[1]</a>. Osiem wieków najlepszej europejskiej tradycji zaklętych w prostym urządzeniu. Już pojedyncza sesja pozwala cofnąć wszelkie zmiany związane z wieloletnim stosowaniem "pajączka". Ruchoma górna część blokowana jest w pozycji zamkniętej z użyciem zasuwki &ndash; jest to sprawdzona technologia, która od pokoleń pomaga dbać o naszą prywatność w chwilach zadumy. Zawias zrealizowany jest w oparciu o wytrzymałą stalową śrubę.
            </Item>
            <Item name={'Kozioł'} base={'goat'} num_thumb={3} pricing={[['2000 zł', 'mebel z zaczepami', 'pokrycie bejcą'], ['-300 zł', 'wersja lekka', 'bez stopni']]} view={openModal}>
                Kozioł także wywodzi się ze średniowiecznych narzędzi tortur <a href="http://niemodlinzamek.pl/sala-tortur/" className="cite" target="_blank" rel="noreferrer">[2]</a>. Jego zadaniem było, krótko mówiąc, wrzynanie się we wrażliwe okolice. Oryginalne rozwiązanie zwieńczone było ostrą stalową krawędzią <a href="https://www.globtroter.pl/zdjecia/208684,polska,dolny,slask,kowary,ul,zamkowa,4,dura,lex,sed,lex.html" className="cite" target="_blank" rel="noreferrer">[3]</a>, której celem było... nieważne. W proponowanym rozwiązaniu, krawędź ta została zastąpiona powierzchnią o szerokości około 3 &ndash; 4 cm, która bezspornie jest niewygodna, ale przy rozsądnym użytkowaniu nie czyni trwałego uszczerbku na zdrowiu. Kozioł posiada stopnie, które mogą zostać wykorzystane jako podpórki dla rąk, lub też pomoc we wdrapaniu się na jego szczyt. Kozioł jest meblem składanym, co ułatwia jego transport i instalację.Opcjonalnie kozioł może być też wykonany w wersji lżejszej, bez pokrycia drewnem na dolnych 30 cm konstrukcji. Zabieg odchudzania wiąże się też jednak z brakiem możlwości zamontowania stopni.
            </Item>
            <Item name={'Kózka'} base={'goatie'} num_thumb={4} pricing={[['1000 zł', 'mebel z zaczepami', 'tapicerka w wybranym kolorze']]} view={openModal}>
                Kózka jest meblem lekkim i stosunkowo wygodnym. Odkręcane siedzisko umożliwia rozbudowę mebla o własne zabawki i acesoria. Kózka wyposażona jest także w dwa zaczepy na wysokości kostki/stopy, pozwalające na sprawne i niezawodne unieruchomienie kończyn dolnych jej pasażera. Siedzisko kózki obite jest gąbką i pokryte sztuczną skórą. Kolory dostępne "od ręki" to: czarny, czerwony, pomarańczowy oraz błękitny.
            </Item>
            <Item name={'Pentagram'} base={'penta'} num_thumb={4} pricing={[['2200 zł', 'mebel z zaczepami', 'zestaw kotw montażowych', 'pokrycie bejcą']]} view={openModal}>
                Połączenie mistycyzmu i elegancji. Symbol mający w zamierzeniach chronić przed wpływem magii i klątwami <a href="https://pl.wikipedia.org/wiki/Pentagram#Symbolika" className="cite" target="_blank" rel="noreferrer">[4]</a>. Rzeczywiście zjawiska te nie będą raczej trapić tych, którzy zostaną rozciągnięci na jego bezdusznej sosnowej ramie. Pomimo ażurowej konstrukcji mebel ten ma sporą masę i bez problemu poskromi każdą powierzoną mu osobę. Aby jednak mógł tego bezpiecznie dokonać, musi zostać dokręcony do ściany w czterech punktach. Ręce mogą zostać rozłożone na boki, bądź też połączone razem przy górnym wierzchołku.
            </Item>
        
            <SectionTitle id={'info'}>Inne informacje</SectionTitle>
        
            <Other name={'Warianty wykończenia'} id={'finish'}>
                <p>Meble wykonane są z drewna sosnowego, które z natury jest bardzo jasne. Aby nadać im nieco mroczniejszego wyglądu są one malowane ciemną bejcą lub lakierobejcą. Kolor widoczny na poniższych zdjęciach to "ciemny orzech".</p>
                <p>Bejca idealnie barwi drewno, wnikając w jego włókna. Jednocześnie pozostawia dobrze widoczny oryginalny układ słojów drewna oraz nie zmienia jego szorstkiej, chropowatej faktury.</p>
                <p>Lakierobejca tworzy na drewnie powłokę ochronną zabezpieczającą przed wilgocią oraz zapewnia większą trwałość koloru. Rysunek słojów jest wciąż widoczny, lecz mniej wyraźny. Pokryte nią drewno staje się gładkie i delikatnie śliskie.</p>
                <p>Przypalenie drewna płomieniem powoduje, że układ słojów staje się bardzo dobrze widoczny a sam mebel nabiera deliatnego przyjemnego zapachu. W dotku natomiast mebel pozostaje odrobinę szorstki. Ten typ wykończenia nie zabezpiecza przed wilgocią.</p>
                <Container className="text-center p-3">
                    <Row>
                        <Col>Sosna naturalna<br /><Image src="img/sosna.jpg" alt="drewno surowe" /></Col>
                        <Col>Bejca<br /><img src="img/bejca.jpg" alt="bejca" /></Col>
                        <Col>Lakierobejca<br /><img src="img/lakierobejca.jpg" alt="lakierobejca" /></Col>
                        <Col>Płomień<br /><img src="img/ogien.jpg" alt="drewno przypalone płomieniem" /></Col>
                    </Row>
                </Container>
                <p>Wszystkie "mobilne" meble podklejone są filcem aby zabezpieczyć przed porysowaniem powierzchnię, na której zostaną ustawione.</p>
                <p>Meble przeznaczone do użytku z kajdankami lub opaskami posiadają mocowania w postaci stalowych "oczek" z karabinkami. To umożliwia szybkie przyczepienie praktycznie każdego systemu mocowania rąk i nóg. Użycie karabinka z linami wymaga natomiast większej ostrożności, bowiem otwaty karabinek posiada krawędź, która z łatwością zahacza się o oplot liny i może zaciągnąć część z jej włókien. Linę najlepiej jest przewlec wprost przez "oczko" lub stosować z karabinkami alpinistycznymi.</p>
                <div className="text-center mw-100 p-3"><Image src="img/extender.jpg" alt="łańcuch z ogniwem łącznikowym" fluid /></div>
                <p>Każdy mebel wykonywany jest od nowa i może mieć dowolny wymiar.</p>
            </Other>
            
            <Other name={'Coś jeszcze?'} id={'others'}>
                <p>Mój warsztat znajduje się na zachodnim skraju Łodzi. Odbiór własny mile widziany. &#128578; Dostawę na terenie Łodzi realizuję z pomocą firmy przeprowadzkowej lub własnego nie za dużego auta. Dalsze dystanse &ndash; do uzgodnienia.</p>
                <p>Realizacja wybranego mebla zajmuje zwykle około dwóch tygodni.</p>
                <p>Cały czas szukam inspiracji i uczę się nowych materiałów. Jeśli w jakieś mrocznej części Internetu widziałeś/widziałaś jakieś ciekawe rozwiązanie albo masz własny pomysł na inny mebel czy maszynę &ndash; możemy porozmawiać o jego realizacji.</p>
            </Other>

            <Other name={'Kontakt'} id={'contact'}>
                <a href="https://m.me/KosmateMeble"><img src="img/messenger.png" alt="Messenger" />KosmateMeble</a><br />
                <a href="https://www.facebook.com/KosmateMeble"><img src="img/facebook.png" alt="Facebook" />KosmateMeble</a><br />
                <a href="https://fetlife.com/OlekTk"><img src="img/fetlife.png" alt="Fetlife" />OlekTk</a><br />
                <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;olek&#64;kosmatemeble.pl"><img src="img/mail.png" alt="e-mail" />olek&#64;kosmatemeble.pl</a><br />
            </Other>
        
            <footer>
                Wszystkie prawa zastrzeżone, 2023 Aleksander "Olek" Mielczarek
            </footer>
        
            <Modal className="mh-100" show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setModalShow(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        W powiększeniu...
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Image src={modalSrc} />
                </Modal.Body>
            </Modal>
        </Container>
    );
}
